@use "sass:math";

body {
  @include flexbox();
  @include flex-direction(column);
  @include align-items(center);
  @include justify-content(center);
  min-height: 100vh;
  margin: 0;
  padding: 0; 
}
* {
  box-sizing: border-box;
}
.row {
  @include flexbox();
  @include flex-direction(column);
  clear: both;
  width: 100%;
  @media (min-width: $screen-md) {
    @include flex-direction(row);
  }
}
.container {
  width: 100%;
  max-width: $screen-xl;
  margin: 0 auto;
}
div[class^="col-"] {
  padding: $gutter * 2;
}

// https://www.gavsblog.com/blog/for-each-while-loops-sass-scss
$columns: 16 !default;

// https://sass-lang.com/d/slash-div
@media (min-width: $screen-md) {
  @for $i from 1 through $columns {
    .col-#{$i} {
        width: math.div(100, $columns) * $i * 1%;
    }
  }
}

$marginbuffers: 5;
@for $i from 0 through $marginbuffers {
  .m-#{$i} {
    margin: $i * ($theme-font-size-base * .5) !important;
  }
  .my-#{$i} {
    margin-top: $i * ($theme-font-size-base * .5) !important;
    margin-bottom: $i * ($theme-font-size-base * .5) !important;
  }
  .mx-#{$i} {
    margin-right: $i * ($theme-font-size-base * .5) !important;
    margin-left: $i * ($theme-font-size-base * .5) !important;
  }
  .mt-#{$i} {
    margin-top: $i * ($theme-font-size-base * .5) !important;
  }
  .mb-#{$i} {
    margin-bottom: $i * ($theme-font-size-base * .5) !important;
  }
  .ml-#{$i} {
    margin-left: $i * ($theme-font-size-base * .5) !important;
  }
  .mr-#{$i} {
    margin-right: $i * ($theme-font-size-base * .5) !important;
  }
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
$paddingbuffers: 5;
@for $i from 0 through $paddingbuffers {
  .p-#{$i} {
    padding: $i * ($theme-font-size-base * .5) !important;
  }
  .py-#{$i} {
    padding-top: $i * ($theme-font-size-base * .5) !important;
    padding-bottom: $i * ($theme-font-size-base * .5) !important;
  }
  .px-#{$i} {
    padding-right: $i * ($theme-font-size-base * .5) !important;
    padding-left: $i * ($theme-font-size-base * .5) !important;
  }
  .pt-#{$i} {
    padding-top: $i * ($theme-font-size-base * .5) !important;
  }
  .pb-#{$i} {
    padding-bottom: $i * ($theme-font-size-base * .5) !important;
  }
  .pl-#{$i} {
    padding-left: $i * ($theme-font-size-base * .5) !important;
  }
  .pr-#{$i} {
    padding-right: $i * ($theme-font-size-base * .5) !important;
  }
}


.full-width {
  position: relative;
  right: 50%;
  left: 50%;
  width: 100vw;
  margin-right: -50vw;
  margin-left: -50vw;
}
.width-100 {
  width: 100%;
}
.d-none {
  display: none;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}