@use "sass:math";
.menu-container {
  @include flexbox();
  @include flex-direction(column);
  @include align-items(center);
  @media (min-width: $screen-lg) {
    @include flex-direction(row);
  }
  h1 {
    margin: 0;
    line-height: 0;
  } 
  .menu-logo {
    display: flex;
    width: 100%;
    padding: $gutter;
    text-align: left;
    @include justify-content(space-between);
    @include align-items(center);
    @media (min-width: $screen-lg) {
      width: auto;
    }
    button {
      flex-grow: 0;
      flex-shrink: 1;
      width: auto;
      background-color: transparent;
      @media (min-width: $screen-lg) {
        display: none;
      }
      &[aria-expanded=true] {
        img {
          filter: brightness(0) saturate(100%) invert(78%) sepia(90%) saturate(565%) hue-rotate(134deg) brightness(84%) contrast(90%);
        }
      }
    }
  }
  nav {
    width: 100%;
    padding: 0 math.div($gutter, 2);
    @include flexbox();
    @include flex-direction(column);
    @include flex-grow(1);
    @media (min-width: $screen-lg) {
      @include flex-direction(row);
      @include justify-content(space-between);
      @include align-items(center);
    }
    background-color: #fff;
    @media (max-width: $screen-lg - 1px) {
      height: auto;
      visibility: visible;
      opacity: 1;
      transition: 20ms all ease-in;
      &[aria-hidden=true] {
        height: 0;
        opacity: 0;
      }
    }
    ul {
      margin: 0;
      li a {
        display: block;
        @media (min-width: $screen-lg) {
          display: inline-block;
        }
      }
      li {
        @media (max-width: $screen-lg - 1px) {
          display: block;
        }
      }
      li.mobile-padded-link {
        @media (max-width: $screen-lg - 1px) {
          margin-top: 20px;
        }
      }
    }
    a:not(.btn) {
      padding: math.div($theme-font-size-base, 2) $theme-font-size-base;
      color: $theme-body-text-color;
      text-decoration: none;
      &:hover {
        color: $theme-primary;
        text-decoration: underline;
      }
    }
  }
}
