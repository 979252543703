@use "sass:math";
.card-container {
  margin: ($gutter * 4) 0 ($gutter * 2) 0;
  background: linear-gradient(to right, transparent 0 49%, $theme-primary 49% 51%, transparent 51%);
  @include flexbox();
  @include flex-direction(column);
  @media (min-width: $screen-md) {
    @include align-items(flex-start);
    @include flex-direction(row);
    background: linear-gradient(to bottom, transparent 0 49%, $theme-primary 49% 51%, transparent 51%);
  }
  gap: ($gutter * 2);
}
.card {
  padding: $gutter * 3;
  padding-top: $gutter * 4;
  background: $color-white;
  position: relative;
  color: lighten($theme-primary, 20);
  margin-bottom: $gutter * 4;
  line-height: 1.7;
  border-radius: 8px;
  flex-basis: calc(100% / 3);
  @media (max-width: $screen-md - 1px) {
    flex-basis: 100%;
    &:last-child {
      margin-bottom: 0;
    }
  }
  h3 {
    color: $theme-primary;
    margin-top: $theme-font-size-base * 1;
    margin-bottom: $theme-font-size-base * 0.5;
  }
  .card-image {
    position: absolute;
    margin-top: 76px * -0.5;
    top: 0;
    left: $gutter * 3;
    background-color: $theme-primary;
    padding: $theme-font-size-base * 1.25;
    border-radius: 999px;
    line-height: 0;
    .far, .fas, .fal {
      color: $color-yellow;
    }
  }
}
@media (min-width: $screen-md) {
  .card {
    margin-top: $gutter * 2;
    + .card {
      margin-top: $gutter * 4;
      + .card {
        margin-top: $gutter * 6;
      }
    }
  }
}
.card-column {
  @media (min-width: $screen-md) {
    @include flex-direction(column);
    @include flex-basis(calc(100% / 3));
  }
}
