%button {
  display: block;
  width: 100%;
  padding: ($button-padding * 2) $button-padding;
  font-size: 1.125rem;
  font-family: $theme-font-family;
  text-align: center;
  text-decoration: none;
  border: 0;
  border-radius: $button-border-radius;
  cursor: pointer;
  transition: background-color .2s ease-in;
  &:focus {
    outline: 2px solid darken($theme-primary, 20);
  }
  &:hover, &:focus, &:active {
    color: initial;
  }
}
button, .btn {
  @extend %button;
  &.btn-sm {
    padding: ($button-padding * 1.5) ($button-padding * .75);
    font-size: 1rem;
  }  
}

.btn-primary {
  color: set-color($theme-primary);
  background-color: $theme-primary;
  &:hover {
    background-color: darken($theme-primary, 10);
  }
}
.btn-secondary {
  color: set-color($theme-secondary);
  background-color: $theme-secondary;
  &:hover {
    background-color: darken($theme-secondary, 10);
  }
}