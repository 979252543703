.btn, button {
  width: 100%;
  min-width: 80px;
  display: inline-block;
  padding-left: $button-padding * 4;
  padding-right: $button-padding * 4;
  font-weight: 700;
  color: $color-white;
  background-color: $theme-primary;
  text-transform: none;
  @media (min-width: $screen-sm) {
    width: auto;
  }
  &:hover, &:focus, &:active {
    color: $color-yellow;
  }
  &.btn-red {
    background-color: $color-berry;
  }
}

.btn-radio {
  width: 50%;
  border: 4px solid $theme-primary;
  &:hover, &:focus, &:active {
    border-color: rgba($theme-secondary, .5);
  }
  &.btn-success {
    border-color: $alert-success;
  }
  &.btn-error {
    border-color: $alert-danger;
  }
}

.btn-option {
  background-color: transparent;
  padding: ($gutter * .25) $gutter;
  font-size: 24px;
  min-width: 24px;
}
.btn.btn-secondary {
  background-color: rgba(5, 5, 23, .5)
}
.btn-danger {
  background-color: #d95a01;
}